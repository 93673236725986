import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
import DecorateLayout from "@/layout/decorate.vue";

const routes = [
    {
        path: "/shop",
        name: "shop",
        meta: { title: "客户管理" },
        redirect: "/shop/customer",
        component: Main,
        children: [
            {
                path: "/shop/customer",
                name: "customer",
                meta: {
                    title: "客户管理",
                    parentPath: "/shop",
                    icon: 'icon_user_guanli',
                    permission: ["view"],
                },
                component: () => import("@/views/shop/customer/lists.vue"),
            },
            {
                path: "/shop/customer_detail",
                name: "customer_detail",
                meta: {
                    hidden: true,
                    title: "客户修改",
                    parentPath: "/shop",
                    prevPath: "/shop/customer",
                    icon: "icon_dianpu_home",
                    permission: ["view", "add"],
                },
                component: () => import("@/views/shop/customer/lists_edit.vue"),
            },
            {
                path: "/shop/staff_management",
                name: "staff_management",
                meta: {
                    hidden: true,
                    title: "员工管理",
                    parentPath: "/shop",
                    prevPath: "/shop/staff",
                    icon: "icon_dianpu_home",
                    permission: ["view","add","detail","delete","edit"],
                },
                component: () => import("@/views/shop/shop/staff/lists.vue"),
            },
            // {
            //     path: "/shop/consumeLog",
            //     name: "consumeLog",
            //     meta: {
            //         title: "消费日志",
            //         parentPath: "/shop",
            //         icon: 'icon_set_jiaoyi',
            //         permission: ["view"],
            //     },
            //     component: () => import("@/views/shop/consume_log/lists.vue"),
            // },
        ],
    },

    {
        path: "/decorate",
        name: "decorate",
        meta: { title: "装修", hidden: true },
        redirect: "/decorate/index",
        component: DecorateLayout,
        children: [
            {
                path: "/decorate/index",
                name: "decorate_index",
                meta: {
                    title: "微页面",
                    parentPath: "/decorate",
                    permission: ["view"],
                },
                component: () => import("@/views/decorate/index.vue"),
            },
            {
                path: "/decorate/category",
                name: "decorate_category",
                meta: {
                    title: "商品分类",
                    parentPath: "/decorate",
                    permission: ["view"],
                },
                component: () => import("@/views/decorate/category.vue"),
            },
            {
                path: "/decorate/cart",
                name: "decorate_cart",
                meta: {
                    title: "购物车",
                    parentPath: "/decorate",
                    permission: ["view"],
                },
                component: () => import("@/views/decorate/cart.vue"),
            },
            {
                path: "/decorate/user",
                name: "decorate_user",
                meta: {
                    title: "个人中心",
                    parentPath: "/decorate",
                    permission: ["view"],
                },
                component: () => import("@/views/decorate/user.vue"),
            },
            {
                path: "/decorate/goods_detail",
                name: "decorate_goods_detail",
                meta: {
                    title: "商品详情",
                    parentPath: "/decorate",
                    permission: ["view"],
                },
                component: () => import("@/views/decorate/goods_detail.vue"),
            },
            {
                path: "/decorate/pc_index",
                name: "decorate_pc_index",
                meta: {
                    title: "PC首页",
                    parentPath: "/decorate",
                    permission: ["view"],
                },
                component: () => import("@/views/decorate/pc_index.vue"),
            },
        ],
    },
];

export default routes;
