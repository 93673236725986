import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
import DecorateLayout from "@/layout/decorate.vue";
import secondary_meun from "@/layout/secondary_meun.vue";

const routes = [
    {
        path: "/goods",
        name: "goods",
        meta: { title: "商品中心" },
        redirect: "/goods/goods_center/goods",
        component: Main,
        children: [
            // {
            //     path: "/goods/survey",
            //     name: "survey",
            //     meta: {
            //         title: "商品池概况",
            //         parentPath: "/goods",
            //         icon: "el-icon-goods",
            //         permission: ["view"],
            //     },
            //     component: () => import("@/views/goods/survey/index.vue"),
            // },
            {
                path: "/goods/category",
                name: "category",
                meta: {
                    title: "商品分类",
                    parentPath: "/goods",
                    icon: "icon-shangchengguanli-weixuanzhong",
                    permission: ["view"],
                },
                component: () => import("@/views/goods/category/index.vue"),
            },
            {
                path: "/goods/category/edit",
                name: "category_edit",
                meta: {
                    hidden: true,
                    title: "商品分类",
                    parentPath: "/goods",
                    icon: "el-icon-goods",
                    permission: ["view", "add"],
                },
                component: () => import("@/views/goods/category/edit.vue"),
            },
            {
                path: "/goods/goods_center",
                name: "goods_center",
                meta: {
                    title: "商品中心",
                    parentPath: "/goods",
                    icon: "el-icon-goods",
                    permission: ["view"],
                },
                component: Blank,
                redirect: "/goods/goods_center/goods",
                children: [
                    {
                        path: "/goods/goods_center/goods",
                        name: "goods_onlineselection",
                        meta: {
                            title: "商品",
                            parentPath: "/goods",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/goods_center/goods/index.vue"),
                    },
                    {
                        path: "/goods/goods_center/platform-goods-detail",
                        name: "goods_detail",
                        meta: {
                            title: "平台商品详情",
                            parentPath: "/goods",
                            permission: ["view"],
                            hidden: true,
                        },
                        component: () =>
                            import(
                                "@/views/goods_center/goods/platform-goods-detail.vue"
                            ),
                    },
                    {
                        path: "/goods/goods_center/self-goods-detail",
                        name: "goods_detail",
                        meta: {
                            title: "自有商品详情",
                            parentPath: "/goods",
                            permission: ["view"],
                            hidden: true,
                        },
                        component: () =>
                            import(
                                "@/views/goods_center/goods/self-goods-detail.vue"
                            ),
                    },
                    {
                        path: "/goods/goods_center/film",
                        name: "film",
                        meta: {
                            title: "电影",
                            parentPath: "/goods",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/goods_center/film/index.vue"),
                    },
                    {
                        path: "/goods/goods_center/other_resources",
                        name: "other_resources",
                        meta: {
                            title: "特色资源",
                            parentPath: "/goods",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/goods_center/other_resources/index.vue"
                            ),
                    },
                    {
                        path: "/goods/goods_center/bulk_pricing",
                        name: "film",
                        meta: {
                            title: "批量定价",
                            parentPath: "/goods",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/goods_center/bulk_pricing/index.vue"
                            ),
                    },
                    {
                        path: "/goods/goods_center/cinema_list",
                        name: "cinema_list",
                        meta: {
                            title: "查看影院",
                            hidden: true,
                            parentPath: "/goods",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/goods_center/bulk_pricing/cinema_list.vue"
                            ),
                    },
                    {
                        path: "/goods/goods_center/city_list",
                        name: "cinema_list",
                        meta: {
                            title: "查看城市",
                            hidden: true,
                            parentPath: "/goods",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/goods_center/bulk_pricing/city_list.vue"
                            ),
                    },
                ],
            },
            {
                path: "/goods/operationmanagement",
                name: "operationmanagement",
                meta: {
                    title: "平台商品包管理",
                    parentPath: "/goods",
                    //   icon: "el-icon-data-line",
                    icon: "icon_danwei",
                    permission: ["view"],
                },
                component: Blank,
                redirect: "/goods/operationmanagement/index",
                children: [
                    {
                        path: "/goods/operationmanagement/index",
                        name: "goods_operationmanagement",
                        meta: {
                            title: "商品包列表",
                            parentPath: "/goods",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/goods/operationmanagement/index.vue"
                            ),
                    },
                    {
                        path: "/goods/operationmanagement/historydate",
                        name: "goods_historydate",
                        meta: {
                            title: "数据变化",
                            parentPath: "/goods",
                            permission: ["view"],
                            hidden: true,
                        },
                        component: () =>
                            import(
                                "@/views/goods/operationmanagement/historydate.vue"
                            ),
                    },
                    {
                        path: "/goods/operation_details",
                        name: "operation_details",
                        meta: {
                            title: "运营管理信息",
                            parentPath: "/goods",
                            permission: ["view"],
                            hidden: true,
                        },
                        component: secondary_meun,
                        redirect: "/goods/operation_details/operation_config",
                        children: [
                            {
                                path: "/goods/operation_details/operation_config",
                                name: "operation_config",
                                meta: {
                                    hidden: true,
                                    title: "运营池配置",
                                    parentPath: "/goods",
                                    prevPath: "/goods/operation_details",
                                    permission: ["view"],
                                },
                                component: () =>
                                    import(
                                        "@/views/goods/operationmanagement/operation_config.vue"
                                    ),
                            },
                            {
                                path: "/goods/operation_details/page_manage",
                                name: "page_manage",
                                meta: {
                                    title: "页面管理",
                                    parentPath: "/goods",
                                    prevPath: "/goods/operation_details",
                                    permission: ["view"],
                                },
                                component: () =>
                                    import(
                                        "@/views/goods/operationmanagement/page_manage.vue"
                                    ),
                                redirect:
                                    "/goods/operationmanagement/shop/index.vue",
                                children: [
                                    {
                                        path: "/goods/operation_details/page_manage/index",
                                        name: "shop_index",
                                        meta: {
                                            title: "商城主页",
                                            parentPath: "/goods",
                                            icon: "icon_dianpu_home",
                                            prevPath:
                                                "/goods/operation_details",
                                            permission: ["view"],
                                        },
                                        component: () =>
                                            import(
                                                "@/views/goods/operationmanagement/shop/index.vue"
                                            ),
                                    },
                                    {
                                        path: "/goods/operation_details/page_manage/category",
                                        name: "shop_category",
                                        meta: {
                                            title: "商品分类",
                                            parentPath: "/goods",
                                            icon: "icon_dianpu_fenlei",
                                            prevPath:
                                                "/goods/operation_details",
                                            permission: ["view"],
                                        },
                                        redirect: "/decorate/category",
                                    },
                                    {
                                        path: "/goods/operation_details/page_manage/shop_goods_detail",
                                        name: "shop_goods_detail",
                                        meta: {
                                            title: "商品详情",
                                            parentPath: "/goods",
                                            icon: "icon_dianpu_xiangqing",
                                            prevPath:
                                                "/goods/operation_details",
                                            permission: ["view"],
                                        },
                                        redirect: "/decorate/goods_detail",
                                    },
                                    {
                                        path: "/goods/operation_details/page_manage/shop_cart",
                                        name: "shop_cart",
                                        meta: {
                                            title: "购物车",
                                            parentPath: "/goods",
                                            icon: "icon_dianpu_shoppingCar",
                                            prevPath:
                                                "/goods/operation_details",
                                            permission: ["view"],
                                        },
                                        redirect: "/decorate/cart",
                                    },
                                    {
                                        path: "/goods/operation_details/page_manage/shop_user",
                                        name: "shop_user",
                                        meta: {
                                            title: "个人中心",
                                            parentPath: "/goods",
                                            prevPath:
                                                "/goods/operation_details",
                                            icon: "icon_user",
                                            permission: ["view"],
                                        },
                                        redirect: "/decorate/user",
                                    },
                                    {
                                        path: "/goods/operation_details/page_manage/shop_tabbar",
                                        name: "shop_tabbar",
                                        meta: {
                                            title: "底部导航",
                                            parentPath: "/goods",
                                            prevPath:
                                                "/goods/operation_details",
                                            icon: "icon_dianpu_daohang",
                                            permission: ["view"],
                                        },
                                        component: () =>
                                            import(
                                                "@/views/goods/operationmanagement/shop/tabbar.vue"
                                            ),
                                    },
                                    {
                                        path: "/goods/operation_details/page_manage/lists",
                                        name: "pages_lists",
                                        meta: {
                                            title: "页面管理",
                                            parentPath: "/goods",
                                            prevPath:
                                                "/goods/operation_details",
                                            permission: ["view"],
                                        },
                                        component: () =>
                                            import(
                                                "@/views/goods/operationmanagement/shop/pages/lists.vue"
                                            ),
                                    },
                                    {
                                        path: "/goods/operation_details/page_manage/shop_theme",
                                        name: "shop_theme",
                                        meta: {
                                            title: "商城风格",
                                            parentPath: "/goods",
                                            prevPath:
                                                "/goods/operation_details",
                                            permission: ["view"],
                                        },
                                        component: () =>
                                            import(
                                                "@/views/goods/operationmanagement/shop/theme.vue"
                                            ),
                                    },
                                ],
                            },
                            {
                                path: "/goods/operation_details/category_manage",
                                name: "page_manage",
                                meta: {
                                    title: "分类管理",
                                    parentPath: "/goods",
                                    prevPath: "/goods/operation_details",
                                    permission: ["view"],
                                },
                                component: () =>
                                    import(
                                        "@/views/goods/operationmanagement/category_manage.vue"
                                    ),
                            },
                            {
                                path: "/goods/category_edit",
                                name: "category_edit",
                                meta: {
                                    hidden: true,
                                    title: "新增分类",
                                    parentPath: "/goods",
                                    prevPath: "/goods/operation_details",
                                },
                                component: () =>
                                    import(
                                        "@/views/goods/operationmanagement/category_edit.vue"
                                    ),
                            },
                            {
                                path: "/goods/operation_details/goods_manage",
                                name: "goods_manage",
                                meta: {
                                    title: "选品管理",
                                    parentPath: "/goods",
                                    prevPath: "/goods/operation_details",
                                    permission: ["view"],
                                },
                                component: () =>
                                    import(
                                        "@/views/goods/operationmanagement/goods_manage.vue"
                                    ),
                            },
                            {
                                path: "/goods/operation_details/pricing_strategy",
                                name: "goods_pricing_strategy",
                                meta: {
                                    title: "定价策略",
                                    parentPath: "/goods",
                                    prevPath: "/goods/operation_details",
                                    permission: ["view"],
                                },
                                component: () =>
                                    import(
                                        "@/views/goods/pricingsettings/pricing_strategy.vue"
                                    ),
                            },
                            {
                                path: "/goods/operation_details/risk_management",
                                name: "goods_risk_management",
                                meta: {
                                    title: "风险控制",
                                    parentPath: "/goods",
                                    prevPath: "/goods/operation_details",
                                    permission: ["view"],
                                },
                                component: () =>
                                    import(
                                        "@/views/goods/pricingsettings/risk_management.vue"
                                    ),
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default routes;
