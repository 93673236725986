













































































import { Component, Vue } from 'vue-property-decorator'
import PopoverInput from '@/components/popover-input.vue'
import LsDialog from '@/components/ls-dialog.vue'
import TemplateSelect from '@/components/shop/template-select/index.vue'
import VueQr from 'vue-qr'
import Perm from '@/components/layout/perm.vue'

@Component({
    components: {
        PopoverInput,
        VueQr,
        LsDialog,
        TemplateSelect,
        Perm
    }
})
export default class DecorateLayout extends Vue {
    $refs!: { templateSelect: any }
    showPreview = false

    prevUrl = ''
    /** S computed **/

    get pagesInfo() {
        const { pagesInfo } = this.$store.state.decorate
        return pagesInfo
    }

    set pagesInfo(val) {
        this.$store.commit('setPagesInfo', val)
    }

    get client() {
        return this.$store.state.decorate.client
    }

    get showPreviewBtn() {
        // 商品详情和购物车不支持预览
        const routeArr = ['/decorate/cart', '/decorate/goods_detail']
        return !routeArr.includes(this.$route.path) && this.client == 'mobile'
    }
    /** E computed **/

    /** S methods **/
    handleConfirm() {
        this.$refs.templateSelect.onTrigger()
    }
    handleSelect(data: any) {
        data.common = JSON.parse(data.common)
        data.content = JSON.parse(data.content)
        this.pagesInfo = data
        this.$store.commit('setPagesData', data.content)
    }
    setPagesInfoName(val: string) {
        this.pagesInfo.name = val
    }
    handleSave(preview = false) {
        const loading = this.$loading({
            lock: true,
            text: '保存中...',
            spinner: 'el-icon-loading'
        })
        return this.$store
            .dispatch('addPages', { preview })
            .then(res => {
                return Promise.resolve(res)
            })
            .catch(res => {
                return Promise.reject(res)
            })
            .finally(() => {
                loading.close()
            })
    }
    handlePreview() {
        if (this.showPreview) {
            this.showPreview = false
            return
        }
        this.handleSave(true).then(res => {
            this.prevUrl = res.url
            this.showPreview = true
        })
    }
    /** E methods **/

    /** S life cycle **/
    created() {
        // 初始化数据
        this.$store.commit('setPagesInfo', { common: {} })
        this.$store.commit('setPagesData', [])
        this.$store.commit('setSelectIndex', -1)
        this.$store.commit('setClient', 'mobile')
    }
    /** E life cycle **/
}
