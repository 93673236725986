import store from "@/store";
import router from "@/router";
export const verify = (act: string) => {
    let permission = store.getters.permission;
    let path = router.currentRoute.path;
    //console.log(permission,router.currentRoute.path,act);
    if (!permission.auth) return false;
    if (permission.auth.length == 0) return true;
    if (permission.auth[path]) {
        return permission.auth[path].includes(act);
    }
    return false;
};
