/*
枚举类型
*/

// 接口Code状态
export enum ApprovalStatus {
    "all" = "", // 全部
    "success" = 2, // 审核成功
    "error" = 1, // 审核失败
    "wait" = 0, // 待审核
}

// 接口Code状态
export enum APIResponseCodeType {
    "success" = 1, // 成功
    "error" = 0, // 失败
    "redirect" = -1, // 重定向
    "page" = 2, // 跳转页面
}

// 页面模式
export enum PageMode {
    "ADD" = "add", // 添加
    "EDIT" = "edit", // 编辑
}

// 订单管理类型
export enum OrderType {
    "all_count", //全部
    "pay_count", //待支付
    "delivery_count", //待收货
    "receive_count", //待发货
    "finish_count", //已完成
    "close_count", //已关闭
}

// 售后订单
export enum AfterSaleType {
    "all", //全部
    "ing", //售后中
    "success", //售后成功
    "fail", //售后拒绝
}

// 商品类型
export enum GoodsType {
    "all",
    "sale",
    "inventory",
    "soldout",
    "warehouse",
    "",
    "platform_sold_out_count",
}

//影院状态
export enum CinemaStatus {
    "noshow" = 2, // 下线
    "vendibility" = 1, // 可售
}

//影院城市状态
export enum CinemaCityStatus {
    "unpriced" = 2, // 待定价
    "priced" = 1, // 定价
}

// 抵扣项目
export enum orderType {
    '普通订单',
    '拼团订单',
    '秒杀订单',
    '砍价订单',
    '虚拟订单',
    '远程订单',
    '集采订单',
    '特色资源订单',
    '本地商品订单',
    '电影订单',
    '售后退款'
}
