






import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import { adminInfo } from "@/api/app";

@Component
export default class App extends Vue {
    // @Action('getPermission') getPermission!: () => void
    @Action("getConfig") getConfig!: () => void;
    async created() {
        // this.getPermission()
        await this.getConfig();
        // 动态设置站点图片
        const link = this.$store.getters.config.platform_ico;
        let $favicon: any = document.querySelector('link[rel="icon"]');
        if ($favicon !== null) {
            $favicon.href = link;
        } else {
            $favicon = document.createElement("link");
            $favicon.rel = "icon";
            $favicon.href = link;
            document.head.appendChild($favicon);
        }

        // 获取token自动登录
        let geturl = window.location.href;
        let getqyinfo: any = geturl.split("?")[1] ? geturl.split("?")[1] : null;
        let getqys: any = getqyinfo ? getqyinfo.split("&") : null;
        let obj: any = {};
        if (getqyinfo) {
            for (let i = 0; i < getqys.length; i++) {
                let item = getqys[i].split("=");
                let key = item[0];
                let value = item[1];
                obj[key] = value;
            }
        }
        if (obj.a != null) {
            await this.$store.commit("setUserInfo", {
                name: "",
                avatar: "",
                token: obj.a,
            });
            const ret = await adminInfo();
            await this.$store.commit("setUserInfo", ret);
            this.$router.replace("/");
        }
    }
}
