


















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Perm extends Vue {
  get hasPermission() {
    const {
      permission: { root, auth },
    } = this;
    const { path, meta } = this.$route;
    if (root) {
      // 为管理员
      return true;
    }

    const actions = auth[path];

    if (!meta?.permission) {
      // 不需要权限的页面
      return true;
    }
    if (!actions) {
      // 需要权限但后台权限表路径对不上
      return false;
    }

    return actions.some((item: string) => {
      return meta?.permission.includes(item);
    });
  }

  get permission() {
    return this.$store.getters.permission;
  }
}
