import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
const routes = [
    {
        path: "/finance",
        name: "finance",
        meta: { title: "结算中心" },
        redirect: "/finance/overview",
        component: Main,
        children: [
            {
                path: "/finance/overview",
                name: "overview",
                meta: {
                    title: "资产概况",
                    parentPath: "/finance",
                    icon: 'icon_shuju',
                    permission: ["view"],
                },
                component: () => import("@/views/finance/overview.vue"),
            },
            {
                path: "/finance/platform_settlement_lists",
                name: "platform_settlement_lists",
                meta: {
                    title: "平台结算",
                    parentPath: "/finance",
                    icon: 'icon_shuju_liuliang',
                    permission: ["view"],
                },
                component: () => import("@/views/finance/platform_settlement_lists.vue"),
            },
            {
                path: "/finance/platform_settlement_detail",
                name: "platform_settlement_detail",
                meta: {
                    title: "平台结算详情",
                    parentPath: "/finance",
                    icon: "el-icon-s-data",
                    permission: ["view"],
                    hidden: true
                },
                component: () => import("@/views/finance/platform_settlement_detail.vue"),
            },
            {
                path: "/finance/settlement_statistics",
                name: "settlement_statistics",
                meta: {
                    title: "供应商结算",
                    parentPath: "/finance",
                    icon: 'icon_dianpu_shoppingCar',
                    permission: ["view"],
                },
                component: () =>
                    import("@/views/finance/settlement_statistics.vue"),
            },
            {
                path: "/finance/settlement_order",
                name: "settlement_order",
                meta: {
                    title: "订单流水",
                    icon: 'tradingdata',
                    parentPath: "/finance",
                    permission: ["view"],
                },
                component: () => import("@/views/finance/settlement_order.vue"),
            },
            // {
            //     path: "/finance/settlement_purchase",
            //     name: "finance_manage",
            //     meta: {
            //         title: "采购结算",
            //         icon: "el-icon-sold-out",
            //         parentPath: "/finance",
            //         permission: ["view"],
            //     },
            //     component: () =>
            //         import("@/views/finance/settlement_purchase.vue"),
            // },
            // {
            //     path: "/finance/settlement_distribution",
            //     name: "settlement_distribution",
            //     meta: {
            //         title: "分销结算",
            //         icon: "el-icon-sell",
            //         parentPath: "/finance",
            //         permission: ["view"],
            //     },
            //     component: () =>
            //         import("@/views/finance/settlement_distribution.vue"),
            // },
        ],
    },
];

export default routes;
