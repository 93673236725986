import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
const routes = [
    {
        path: "/trade",
        name: "trade",
        meta: { title: "订单中心" },
        redirect: "/trade/order",
        component: Main,
        children: [
            {
                path: "/trade/order",
                name: "trade_manage",
                meta: {
                    title: "订单管理",
                    parentPath: "/trade",
                    icon: 'icon_order_guanli',
                    permission: ["goods_order","movie_order","resource_order",],
                },
                component: () => import("@/views/trade/order.vue"),
            },
            {
                path: "/trade/order_detail",
                name: "trade_detail",
                meta: {
                    hidden: true,
                    title: "订单详情",
                    parentPath: "/trade",
                    prevPath: "/trade/order",
                    permission: ["goods_order_detail","movie_order_detail","resource_order_detail","zt_detail"],
                },
                component: () => import("@/views/trade/order_detail.vue"),
            },
            {
                path: "/trade/purchase_order",
                name: "purchase_order",
                meta: {
                    title: "采购单管理",
                    parentPath: "/trade",
                    icon: 'icon_order_shouhou',
                    permission: ["purchase"],
                },
                component: () => import("@/views/trade/purchase_order.vue"),
            },
            {
                path: "/trade/writeoff_order",
                name: "writeoff_order",
                meta: {
                    title: "核销订单管理",
                    parentPath: "/trade",
                    icon: 'icon_hexiao_order',
                    permission: ["zt"],
                },
                component: () => import("@/views/trade/writeoff_order.vue"),
            },
            {
                path: "/trade/writeoff-order-detail",
                name: "WriteOffOrderDetail",
                meta: {
                    hidden: true,
                    title: "核销订单详情",
                    parentPath: "/trade",
                    icon: 'icon_hexiao_order',
                    permission: ["zt"],
                },
                component: () => import("@/views/trade/writeoff_order_detail.vue"),
            },
        ],
    },
];

export default routes;
