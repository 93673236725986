import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
import Secondary from "@/layout/secondary.vue";

const routes = [
    {
        path: "/card",
        name: "card",
        meta: { title: "卡券积分" },
        redirect: "/card/makeCard/cardSaleOverview",
        component: Main,
        children: [
            {
                path: "/card/makeCard/cardSaleOverview",
                name: "makeCard",
                meta: {
                    title: "福利卡券",
                    parentPath: "/card",
                    icon: "icon-pingtaishezhi",
                },
                component: Blank,
                redirect: "/card/makeCard/productFile",
                children: [
                 {
                        path: "/card/makeCard/cardSaleOverview",
                        name: "cardSaleOverview",
                        meta: {
                            title: "券销售概况",
                            parentPath: "/card",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/cardSaleOverview/index.vue"
                            ),
                    },
                    //
                    {
                        path: "/card/makeCard/productFile",
                        name: "productFile",
                        meta: {
                            title: "产品档案",
                            parentPath: "/card",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/productFile/index.vue"
                            ),
                    },

                    {
                        path: "/card/makeCard/productFileDetail",
                        name: "productFileDetail",
                        meta: {
                            title: "产品档案详情",
                            hidden: true,
                            parentPath: "/card",
                            permission: ["detail"],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/productFile/productFileDetail.vue"
                            ),
                    },
                    {
                        path: "/card/makeCard/productFileAdd",
                        name: "productFileAdd",
                        meta: {
                            title: "添加产品档案",
                            hidden: true,
                            parentPath: "/card",
                            permission: ["add"],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/productFile/productFileAdd.vue"
                            ),
                    },
                    //
                    {
                        path: "/card/makeCard/materialCard",
                        name: "materialCard",
                        meta: {
                            title: "实体券订单",
                            parentPath: "/card",
                            permission: ["view_back"],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/materialCard/index.vue"
                            ),
                    },
                    {
                        path: "/card/makeCard/reserveCardAdd",
                        name: "reserveCardAdd",
                        meta: {
                            title: "新增备券",
                            hidden: true,
                            parentPath: "/card",
                            permission: ["add_back"],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/materialCard/reserveCardAdd.vue"
                            ),
                    },
                    {
                        path: "/card/makeCard/reserveCardDetail",
                        name: "reserveCardDetail",
                        meta: {
                            title: "备券详情",
                            hidden: true,
                            parentPath: "/card",
                            permission: [],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/materialCard/reserveCardDetail.vue"
                            ),
                    },
                    {
                        path: "/card/makeCard/voucherCardAdd",
                        name: "voucherCardAdd",
                        meta: {
                            title: "新增实体券",
                            hidden: true,
                            parentPath: "/card",
                            permission: ["add_order"],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/materialCard/voucherCardAdd.vue"
                            ),
                    },
                    {
                        path: "/card/makeCard/voucherCardDetail",
                        name: "voucherCardDetail",
                        meta: {
                            title: "实体券详情",
                            hidden: true,
                            parentPath: "/card",
                            permission: ["viedetail_orderw"],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/materialCard/voucherCardDetail.vue"
                            ),
                    },
                    //
                    {
                        path: "/card/makeCard/electronCard",
                        name: "electronCard",
                        meta: {
                            title: "电子券订单",
                            parentPath: "/card",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/electronCard/index.vue"
                            ),
                    },
                    {
                        path: "/card/makeCard/electronCardAdd",
                        name: "electronCardAdd",
                        meta: {
                            title: "电子券订单申请",
                            hidden: true,
                            parentPath: "/card",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/electronCard/electronCardAdd.vue"
                            ),
                    },
                    {
                        path: "/card/makeCard/electronCardDetail",
                        name: "electronCardDetail",
                        meta: {
                            title: "电子券订单申请详情",
                            hidden: true,
                            parentPath: "/card",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/electronCard/electronCardDetail.vue"
                            ),
                    },
                    {
                        path: "/card/makeCard/electronCardEdit",
                        name: "electronCardDetail",
                        meta: {
                            title: "编辑电子券订单",
                            hidden: true,
                            parentPath: "/card",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/electronCard/electronCardEdit.vue"
                            ),
                    },
                    {
                        path: "/card/makeCard/cardOperation",
                        name: "cardOperation",
                        meta: {
                            title: "券操作",
                            parentPath: "/card",
                            permission: ["index"],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/cardOperation/index.vue"
                            ),
                    },
                    {
                        path: "/card/makeCard/cardOperationAdd",
                        name: "cardOperationDetail",
                        meta: {
                            title: "券操作详情",
                            hidden: true,
                            parentPath: "/card",
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/cardOperation/cardOperationAdd.vue"
                            ),
                    },
                    {
                        path: "/card/makeCard/cardOperationAdd",
                        name: "cardOperationAdd",
                        meta: {
                            title: "新增券操作",
                            hidden: true,
                            parentPath: "/card",
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/cardOperation/cardOperationAdd.vue"
                            ),
                    },
                    // //
                    // {
                    //     path: "/card/makeCard/orderEdit",
                    //     name: "orderEdit",
                    //     meta: {
                    //         title: "订单修改",
                    //         parentPath: "/card",
                    //         permission: ["view"],
                    //     },
                    //     component: () =>
                    //         import("@/views/card/makeCard/orderEdit/index.vue"),
                    // },
                    {
                        path: "/card/makeCard/orderEditDetail",
                        name: "orderEditDetail",
                        meta: {
                            title: "订单修改详情",
                            hidden: true,
                            parentPath: "/card",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/orderEdit/orderEditDetail.vue"
                            ),
                    },
                    {
                        path: "/card/makeCard/orderEditAdd",
                        name: "orderEditAdd",
                        meta: {
                            title: "新增订单修改",
                            hidden: true,
                            parentPath: "/card",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/card/makeCard/orderEdit/orderEditAdd.vue"
                            ),
                    },
                ],
            },
            {
                path: "/card/pointManage/pointOrder",
                name: "pointManage",
                meta: {
                    title: "积分管理",
                    parentPath: "/card",
                    icon: "icon-pingtaishezhi",
                },
                component: Blank,
                redirect: "/card/pointManage/pointOrder",
                children: [
                    {
                        path: "/card/pointManage/pointOrder",
                        name: "pointOrder",
                        meta: {
                            title: "积分订单",
                            parentPath: "/card",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/card/pointManage/pointOrder/index.vue"
                            ),
                    },
                    {
                        path: "/card/pointManage/pointOrderAdd",
                        name: "pointOrderAdd",
                        meta: {
                            title: "新建积分订单",
                            hidden:true,
                            parentPath: "/card",
                            permission: ["add"],
                        },
                        component: () =>
                            import(
                                "@/views/card/pointManage/pointOrder/pointOrderAdd.vue"
                            ),
                    },

                    {
                        path: "/card/pointManage/pointOrderDetail",
                        name: "pointOrderDetail",
                        meta: {
                            title: "积分订单详情",
                            hidden:true,
                            parentPath: "/card",
                            permission: ["detail"],
                        },
                        component: () =>
                            import(
                                "@/views/card/pointManage/pointOrder/pointOrderDetail.vue"
                            ),
                    },
                    {
                        path: "/card/pointManage/sendList",
                        name: "sendList",
                        meta: {
                            title: "发放明细",
                            hidden:true,
                            parentPath: "/card",
                            permission: ["senders"],
                        },
                        component: () =>
                            import(
                                "@/views/card/pointManage/pointOrder/sendList.vue"
                                ),
                    },
                    {
                        path: "/card/pointManage/handleLog",
                        name: "handleLog",
                        meta: {
                            title: "操作日志",
                            hidden:true,
                            parentPath: "/card",
                            permission: ["log"],
                        },
                        component: () =>
                            import(
                                "@/views/card/pointManage/pointOrder/handleLog.vue"
                                ),
                    },
                ],
            },
        ],
    },
    {
        path: "/card/pointManage/template",
        name: "pointOrderTemplate",
        meta: {
            title: "编辑贺卡",
            hidden:true,
            parentPath: "/card",
            permission: ["view"],
            prevPath: '/'
        },
        component: () => import('@/template-editor/client/pages/layout.vue'),
        redirect: '/card/pointManage/template-editor',
        children: [
            {
                path: '/card/pointManage/template-editor',
                name: 'name',
                meta: {
                    hidden: true,
                    title: '编辑贺卡',
                    prevPath: '/template',
                    permission: ['view'],
                },
                component: () => import('@/template-editor/client/pages/editor/Index.vue')
            }
        ]
    },
];

export default routes;
