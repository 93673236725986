import { ActionTree, Module, MutationTree } from "vuex";
import { AppState, RootState } from "../type";
import { GetterTree } from "vuex";
import { apiAuth, apiconfig } from "@/api/app";
import { asyncRoutes } from "@/router";
import { deepClone, getMenu } from "@/utils/util";
import Cache from "@/utils/cache";

const state: AppState = {
    permission: {},
    config: {},
    menu: [],
    tokenurl: "",
    storeId: null,
};

const getters: GetterTree<AppState, RootState> = {
    permission: (state) => state.permission,
    config: (state) => state.config,
    menu: (state) => state.menu,
    tokenurl: (state) => state.tokenurl,
    storeId: (state) => state.storeId,
};

const mutations: MutationTree<AppState> = {
    /**
     * @description 设置权限
     * @param { Object } state
     * @param { Array } data
     */
    setPermission(state, data) {
        state.permission = data;
    },
    /**
     * @description 设置配置
     * @param { Object } state
     * @param { Array } data
     */
    setCoonfig(state, data) {
        state.config = data;
    },
    setMenu(state, data) {
        state.menu = data;
    },
    setTokenUrl(state, data) {
        state.tokenurl = data;
    },
    setStoreId(state, data) {
        state.storeId = data;
    },
};

const actions: ActionTree<AppState, RootState> = {
    /**
     * @description 获取权限
     * @param { Object } state
     * @param { Function } commit
     */
    getPermission({ state, commit }) {
        return apiAuth().then((res) => {
            let menu: Array<any> = [];
            let permission = res.auth;
            let Routes = deepClone(asyncRoutes);
            if (res.root) {
                menu = Routes;
            } else {
                menu = getMenu(Routes, permission);
            }
            commit("setMenu", menu);
            commit("setPermission", res);
            return Promise.resolve();
        });
    },
    getConfig({ state, commit }) {
        return apiconfig().then((res) => {
            commit("setCoonfig", res);
            return Promise.resolve();
        });
    },
    getTokenUrl({ state, commit }) {
        console.log("getTokenUrl");
    },
};

const app: Module<AppState, RootState> = {
    state,
    mutations,
    actions,
    getters,
};

export default app;
