import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
const routes = [
    {
        path: "/data_center",
        name: "data_center",
        meta: { title: "数据中心" },
        redirect: "/data_center/operator/overview",
        component: Main,
        children: [
            {
                path: "/data_center/operator/overview",
                name: "operator",
                meta: {
                    title: "运营视窗",
                    parentPath: "/data_center",
                    icon: "icon_shuju",
                    permission: ["view", "detail"],
                },
                component: () =>
                    import("@/views/data_center/operator/overview.vue"),
            },
            {
                path: "/data_center/sales/overview",
                name: "sales",
                meta: {
                    title: "销售视窗",
                    parentPath: "/data_center",
                    icon: "icon_shuju_liuliang",
                    permission: ["view", "detail"],
                },
                component: () =>
                    import("@/views/data_center/sales/overview.vue"),
            },
            {
                path: "/data_center/services/overview",
                name: "services",
                meta: {
                    title: "服务视窗",
                    parentPath: "/data_center",
                    icon: "icon-taocanguanli",
                    permission: ["view", "detail"],
                },
                component: () =>
                    import("@/views/data_center/services/overview.vue"),
            },
            {
                path: "/data_center/report",
                name: "report",
                meta: {
                    title: "数据视窗",
                    parentPath: "/data_center",
                    icon: "icon_caiwu_yue",
                    permission: ["view", "detail"],
                },
                component: Blank,
                redirect: "/data_center/report/dataDetail",
                children: [
                    {
                        path: "/data_center/report/dataDetail",
                        name: "dataDetail",
                        meta: {
                            title: "报表明细",
                            parentPath: "/data_center",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/data_center/report/dataDetail.vue"),
                    },
                    {
                        path: "/data_center/report/download",
                        name: "download",
                        meta: {
                            title: "下载中心",
                            parentPath: "/data_center",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/data_center/report/download.vue"),
                    },
                ],
            },
        ],
    },
];

export default routes;
