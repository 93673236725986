import request from "@/plugins/axios";
import * as Interface from "./type/shop";

/** S 商城列表 **/
// 商城列表
export const apiShopLists = (params: any) =>
    request.get("/shop.shop/lists", { params });

// 添加商城

export const apiShopAdd = (params: any) =>
    request.post("/shop.shop/add", params);

export const apiShopSuperAdd = (params: any) =>
    request.post("/shop.shop/superAdd", params);

// 商城详情
export const apiShopDetail = (params: any) =>
    request.get("/shop.shop/detail", { params });

// 编辑商城

export const apiShopEdit = (params: any) =>
    request.post("/shop.shop/edit", params);

export const apiShopSuperEdit = (params: any) =>
    request.post("/shop.shop/superEdit", params);

// 删除商城
export const apiShopDelete = (params: any) =>
    request.post("/shop.shop/switchStatus", params);

// export const apiShopDelete = (params: any) =>
//     request.post("/shop.shop/delete", params);

// 修改超级管理员
export const apiShopChangeSuperAdmin = (params: any) =>
    request.post("/shop.shop/changeSuperAdmin", params);

// 修改商城状态
export const apiShopSwitchStatus = (params: any) =>
    request.post("/shop.shop/switchStatus", params);

// 历史套餐
export const apisetMealLogLists = (params: any) =>
    request.get("/shop.shop/setMealLogLists", { params });

// 修改商城备注
export const apiChangeRemark = (params: any) =>
    request.post("/shop.shop/changeRemark", params);

export const apiGetSupplier = (params: any) =>
    request.get("/shop.shop/getSupplierList", { params });
/** E 商城列表 **/

/** S 套餐列表 **/
// 套餐列表
export const apiSetMealLists = (params: any) =>
    request.get("/shop.set_meal/lists", { params });

// 添加套餐
export const apiSetMealAdd = (params: any) =>
    request.post("/shop.set_meal/add", params);

// 套餐详情
export const apiSetMealDetail = (params: any) =>
    request.get("/shop.set_meal/detail", { params });
export const apiToShop = (params: any) =>
    request.get("/shop.shop/toShop", { params });

// 编辑套餐
export const apiSetMealEdit = (params: any) =>
    request.post("/shop.set_meal/edit", params);

// 删除套餐
export const apiSetMealDelete = (params: any) =>
    request.post("/shop.set_meal/del", params);

// 修改套餐状态
export const apiSetMealSwitchStatus = (params: any) =>
    request.post("/shop.set_meal/status", params);

/** E 套餐列表 **/

/** S 客户管理 */
// 客户列表
export const apiGetCustomerList = (params: any) =>
    request.get("platform_business.BusinessInfo/lists", { params });
//获取客户列表
export const apiGetCustomerListsNopage = (params: any) =>
    request.get("platform_business.BusinessInfo/listsNopage", {
        params,
    });

//客户新增
export const apiCustomerAdd = (params: any) =>
    request.post("platform_business.BusinessInfo/add", params);

//获取客户详情
export const apiGetCustomerInfo = (params: any) =>
    request.get("platform_business.BusinessInfo/detail", { params });

export const apiGetAppList = (params: any) =>
    request.post("platform_business.BusinessInfo/getAppList", { params });

//客户编辑

export const apiCustomerEdit = (params: any) =>
    request.post("platform_business.BusinessInfo/edit", params); //员工管理

//添加员工
export const apiSaveStaffIndex = (params: any) =>
    request.post("staff.Staff/addstaff", params);

//编辑员工
export const apieditStaffIndex = (params: any) =>
    request.post("staff.Staff/editstaff", params);

//删除员工
export const apideleteStaffIndex = (params: any) =>
    request.post("staff.Staff/deletestaff", params);

//冻结员工状态
export const apichangeStaffIndex = (params: any) =>
    request.post("staff.Staff/changeStatus", params);

//启用员工状态
export const apienableStaffIndex = (params: any) =>
    request.post("staff.Staff/enable", params);

//上传客户id
export const apiimportIndex = (params: any) =>
    request.post("staff.Staff/import", params); //批量删除员工

export const apideleteStaffallIndex = (params: any) =>
    request.post("staff.Staff/deleteall", params);

//获取员工列表
export const apiStaffListsIndex = (params: any) =>
    request.get("staff.Staff/stafflists", { params }); //下载员工模板

export const apidownLoadIndex = (params: any) =>
    request.post("staff.Staff/export", params);
//客户删除

export const apiCustomerDelete = (params: any) =>
    request.post("platform_business.BusinessInfo/delete", params);

//客户状态修改
export const apiSetCustomerStatus = (params: any) =>
    request.post("platform_business.BusinessInfo/switchStatus", params);

//获取应用权限列表
export const apiGetappListsNopage = (params: any) =>
    request.get("platform_business.BusinessInfo/appListsNopage", { params });

//获取用户当前应用权限
export const apiGetBusinessAppsIndex = (params: any) =>
    request.post("platform_business.BusinessInfo/getBusinessAppsIndex", params);

//应用权限保存

export const apiSaveBusinessAppsIndex = (params: any) =>
    request.post("platform_business.BusinessInfo/businessAppsIndex", params);

//影响选择商品包
export const apiSetShopPackge = (params: any) =>
    request.post("shop.Shop/setShopPackge", params);

/** E 客户管理 */

// S 装饰

//获取主题配置
export const apiThemeConfig = (params: any) =>
    request.get("/theme.DecorateThemeConfig/getContent", { params });

// 设置主题配置
export const apiThemeConfigSet = (data: any) =>
    request.post("/theme.DecorateThemeConfig/setContent", data);

// 保存装修页面
export const apiThemePageLists = (params: any) =>
    request.get("/theme.DecorateThemePage/lists", { params });

// 保存装修页面
export const apiThemePageAdd = (data: any) =>
    request.post("/theme.DecorateThemePage/add", data);

// 编辑装修页面
export const apiThemePageEdit = (data: any) =>
    request.post("/theme.DecorateThemePage/edit", data);

// 编辑装修Pc页面
export const apiPcThemePageEdit = (data: any) =>
    request.post("/market_goods.PcDecorateThemePage/setPage", data);

// 编辑装修Pc页面-管理中心
export const apiPcThemePageEditCC = (data: any) =>
    request.post("/theme.PcDecorateThemePage/setpage", data);

// 编辑装修Pc页面
export const apiPcThemePageDetail = (params: { id?: number; type?: number }) =>
    request.get("/market_goods.PcDecorateThemePage/getPage", { params });

// 编辑装修Pc页面-管理中心
export const apiPcThemePageDetailCC = (params: {
    id?: number;
    type?: number;
}) => request.get("/theme.PcDecorateThemePage/getPage", { params });

// 装修页面详情
export const apiThemePageDetail = (params: any) =>
    request.get("/theme.DecorateThemePage/getPage", { params });

// 设置装修页面为主页
export const apiThemePageSetHome = (data: any) =>
    request.post("/theme.DecorateThemePage/setHome", data);

// 删除装修页面
export const apiThemePageDel = (data: { id: number }) =>
    request.post("/theme.DecorateThemePage/del", data);

// 页面模板
export const apiSystemThemePage = (params: any) =>
    request.get("/theme.SystemThemePage/lists", { params });

// 店铺主页
export const apiThemePageIndex = (data: any) =>
    request.post("/theme.DecorateThemePage/index", data);

// PC主页
export const apiPcThemePageIndex = () =>
    request.get("/market_goods.PcDecorateThemePage/index");

// PC主页-管理中心
export const apiPcThemePageIndexCC = () =>
    request.get("/theme.PcDecorateThemePage/index");

// E 装饰

/** S 素材管理 **/

// 添加文件分类
export const apiFileAddCate = (data: Interface.FileAddCate_Req) =>
    request.post("/file/addCate", data);

// 编辑文件分类
export const apiFileEditCate = (data: Interface.FileEditCate_Req) =>
    request.post("/file/editCate", data);

// 删除文件分类
export const apiFileDelCate = (data: { id: number }) =>
    request.post("/file/delCate", data);

// 文件分类列表
export const apiFileListCate = (params: any) =>
    request.get("/file/listCate", { params });

// 文件列表
export const apiFileList = (params: any) =>
    request.get("/file/lists", { params });

// 文件删除
export const apiFileDel = (data: { ids: any[] }) =>
    request.post("/file/delete", data);

// 文件移动
export const apiFileMove = (data: { ids: any[]; cid: number }) =>
    request.post("/file/move", data);

// 文件重命名
export const apiFileRename = (data: { id: number; name: string }) =>
    request.post("file/rename", data);

/** E 素材管理 **/

/** S 选品管理 **/

// 挑选商品列表
export const apiGoodsPackgeGoodsLists = (params: any) =>
    request.get("goods_packge.Goods/lists", { params });

// 已经挑选商品列表
export const apiGoodsPackgeGoodsPackgeGoodsLists = (params: any) =>
    request.get("goods_packge.Goods/packgeGoodsLists", { params });

// 添加绑定商品
export const apiGoodsPackgeGoodsAddCategoryGoods = (data: any) =>
    request.post("goods_packge.Goods/addCategoryGoods", data);

// 删除绑定商品
export const apiGoodsPackgeGoodsDeleteCategoryGoods = (data: any) =>
    request.post("goods_packge.Goods/deleteCategoryGoods", data);

// 删除绑定商品
export const apiGoodsPackgeGoodsDeleteGoods = (data: any) =>
    request.post("goods_packge.Goods/deleteGoods", data);

// 商品上下架
export const apiGoodsPackgeGoodsStatusGoods = (data: any) =>
    request.post("goods_packge.Goods/statusGoods", data);

/** E 选品管理 **/

/** S 消费日志 **/

export const apiBusinessAccountLists = (params: any) =>
    request.get("business_account.BusinessAccount/lists", { params });

// 删除绑定商品
export const apiBusinessAccountAdd = (data: any) =>
    request.post("business_account.BusinessAccount/add", data);

export const apiSelffetchShopList = (params: any) =>
    request.get("/shop.shop/getSelfShopList", { params });

export const apiSelffetchVerifierList = (params: any) =>
    request.get("/shop.shop/getSelfVerifierList", { params });

/** E 消费日志 **/
//获取品牌列表
export const apiBrandList = (params: any) =>
    request.get("goods.GoodsBrand/getGoodsBrandList", { params });
//添加品牌
export const apiAddBrand = (data: any) =>
    request.post("goods.GoodsBrand/addGoodsBrand", data);
