import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
const routes = [
    {
        path: "/supplier",
        name: "supplier",
        meta: { title: "供应商管理" },
        redirect: "/supplier/supplier_list",
        component: Main,
        children: [
            {
                path: "/supplier/supplier_list",
                name: "supplier_list",
                meta: {
                    title: "供应商列表",
                    parentPath: "/supplier",
                    icon: 'icon_gongyingshang',
                    permission: ["view"],
                },
                component: () =>
                    import("@/views/supplier/supplier_list/index.vue"),
            },
            // {
            //     path: "/supplier/supplier_custom_list",
            //     name: "supplier_custom_list",
            //     meta: {
            //         title: "供应商客户信息",
            //         parentPath: "/supplier",
            //         icon: 'icon_user_dengji',
            //         permission: ["view","staff"],
            //     },
            //     component: () =>
            //         import("@/views/supplier/supplier_list/customer_list.vue"),
            // },
            {
                path: "/supplier/lists_detail",
                name: "lists_detail",
                meta: {
                    hidden: true,
                    title: "商城信息",
                    parentPath: "/supplier",
                    prevPath: "/supplier/supplier_list",
                    icon: "icon_dianpu_home",
                    permission: ["view"],
                },
                component: () =>
                    import("@/views/supplier/supplier_list/lists_edit.vue"),
            },
            {
                path: "/supplier/modify_account",
                name: "modify_account",
                meta: {
                    hidden: true,
                    title: "修改超级管理员账号",
                    parentPath: "/supplier",
                    prevPath: "/supplier/supplier_list",
                    icon: "icon_dianpu_home",
                    permission: ["view"],
                },
                component: () =>
                    import("@/views/supplier/supplier_list/modify_account.vue"),
            },
        ],
    },
];

export default routes;
