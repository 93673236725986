import Vue from "vue";
import "./plugins/axios";
import "./plugins/element";
import "./plugins/umy-ui";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./permission";
import "nprogress/nprogress.css";
import "./styles/element-variables.scss";
import appMixin from "./mixin/app";
import "./directives/action";
import { getImageUri } from "./utils/util";
import { verify } from "./utils/permission";
import * as utils from "./utils/util";
import '@/template-editor/client/common/styles/variables.scss' //模板编辑 自定义 css
import '@/template-editor/client/common/styles/index.scss' // 自定义 css
Vue.prototype.$utils = utils;
Vue.config.productionTip = false;

Vue.prototype.$getImageUri = getImageUri;
Vue.prototype.$veifyPer=verify;

Vue.mixin(appMixin);
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
