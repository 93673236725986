


















import { Component, Prop, Vue } from "vue-property-decorator";
import Layout from "@/layout/index.vue";
import LsAside from "@/components/layout/aside-control-center.vue";
import LsTabs from "@/components/layout/tabs.vue";
import { asyncRoutes } from "@/router";

@Component({
  components: {
    Layout,
    LsAside,
    LsTabs,
  },
})
export default class Aside extends Vue {
  get asideMenu() {
    const { meta } = this.$route;
    let menu: any = this.$store.getters.menu;
    let item = meta?.moduleName
      ? menu.find((item: any) => item.meta?.moduleName === meta?.moduleName)
      : menu.find((item: any) => item.path === meta?.parentPath);
    return item?.children;
  }

  get hideAsideMenu() {
    return this.asideMenu?.every((item: any) => item.meta?.hidden);
  }

  created() {}
}
