import Blank from "@/layout/blank.vue";
import MainNoHeader from "@/layout/main-noHeader.vue";

import secondary_meun from "@/layout/secondary_meun-control-center.vue";

const routes = [
    {
        path: "/control-center",
        name: "control-center",
        meta: {
            title: "管理中心",
            hidden: true,
        },
        redirect: "/control-center/shop/index",
        component: MainNoHeader,
        children: [
            {
                path: "/control-center/shop",
                name: "shop",
                meta: {
                    title: "店铺",
                    parentPath: "/control-center",
                },
                redirect: "/control-center/goods/lists",
                component: Blank,
                children: [
                    {
                        path: "/control-center/shop/index",
                        name: "shop_index",
                        meta: {
                            title: "商城主页",
                            parentPath: "/control-center",
                            icon: "icon_dianpu_home",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/mall/control-center/shop/index.vue"
                            ),
                    },
                    {
                        path: "/control-center/shop/pages",
                        name: "shop_pages",
                        meta: {
                            title: "微页面",
                            parentPath: "/control-center",
                            icon: "icon_dianpu_weiyem",
                            permission: ["view"],
                        },
                        redirect: "/control-center/shop/pages/lists",
                        component: secondary_meun,
                        children: [
                            {
                                path: "/control-center/shop/pages/lists",
                                name: "pages_lists",
                                meta: {
                                    title: "页面管理",
                                    parentPath: "/control-center",
                                    prevPath: "/control-center/shop/pages",
                                    permission: ["view"],
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/shop/pages/lists.vue"
                                    ),
                            },
                            {
                                path: "/control-center/shop/pages/template",
                                name: "pages_template",
                                meta: {
                                    title: "页面模板",
                                    parentPath: "/control-center",
                                    prevPath: "/control-center/shop/pages",
                                    permission: ["view"],
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/shop/pages/template.vue"
                                    ),
                            },
                        ],
                    },
                    {
                        path: "/control-center/shop/theme",
                        name: "shop_theme",
                        meta: {
                            title: "商城风格",
                            parentPath: "/control-center",
                            icon: "icon_dianpu_fengge",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/mall/control-center/shop/theme.vue"
                            ),
                    },
                    {
                        path: "/control-center/shop/material",
                        name: "material",
                        meta: {
                            title: "素材中心",
                            parentPath: "/control-center",
                            icon: "icon_dianpu_sucai",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/mall/control-center/shop/material.vue"
                            ),
                    },
                    {
                        path: "/control-center/shop/pc",
                        name: "pc",
                        meta: {
                            title: "PC商城",
                            parentPath: "/control-center",
                            icon: "icon_pcshop",
                            permission: ["view"],
                        },
                        redirect: "/control-center/shop/pc/index",
                        component: secondary_meun,
                        children: [
                            {
                                path: "/control-center/shop/pc/index",
                                name: "pc_index",
                                meta: {
                                    title: "首页装修",
                                    parentPath: "/control-center",
                                    prevPath: "/control-center/shop/pc",
                                    permission: ["view"],
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/shop/pc/index.vue"
                                    ),
                            },
                            {
                                path: "/control-center/shop/pc/adv",
                                name: "pc_adv",
                                meta: {
                                    title: "广告管理",
                                    parentPath: "/control-center",
                                    prevPath: "/control-center/shop/pc",
                                    permission: ["view"],
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/shop/pc/adv.vue"
                                    ),
                            },
                        ],
                    },
                    {
                        path: "/control-center/shop/tabbar",
                        name: "shop_tabbar",
                        meta: {
                            title: "底部导航",
                            parentPath: "/control-center",
                            icon: "icon_dianpu_daohang",
                            permission: ["view"],
                            decorate: true,
                        },
                        component: () =>
                            import(
                                "@/views/mall/control-center/shop/tabbar.vue"
                            ),
                    },
                    {
                        path: "/control-center/shop/shop_decoration",
                        name: "shop_decoration",
                        meta: {
                            title: "商城装修",
                            parentPath: "/control-center",
                            permission: ["view"],
                        },
                        // redirect: "/control-center/shop/tabbar",
                        redirect: "/control-center/shop/shop_index_cc",
                        component: secondary_meun,
                        children: [
                            {
                                path: "/control-center/shop/shop_index_cc",
                                name: "shop_index_cc",
                                meta: {
                                    title: "首页装修",
                                    parentPath: "/control-center",
                                    prevPath:
                                        "/control-center/shop/shop_decoration",
                                    icon: "icon_dianpu_daohang",
                                    permission: ["view"],
                                    decorate: true,
                                    hidden: true,
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/decorate/index.vue"
                                    ),
                            },
                            {
                                path: "/control-center/shop/shop_index_pc",
                                name: "shop_index_pc",
                                meta: {
                                    title: "pc首页装修",
                                    parentPath: "/control-center",
                                    prevPath:
                                        "/control-center/shop/shop_decoration",
                                    icon: "icon_dianpu_daohang",
                                    permission: ["view"],
                                    decorate: true,
                                    hidden: true,
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/decorate/pc_index.vue"
                                    ),
                            },
                            //   {
                            //     path: '/control-center/shop/tabbar',
                            //     name: 'shop_tabbar',
                            //     meta: {
                            //       title: '底部导航',
                            //       parentPath: '/control-center',
                            //       prevPath: '/control-center/shop/shop_decoration',
                            //       icon: 'icon_dianpu_daohang',
                            //       permission: ['view'],
                            //       decorate: true,
                            //     },
                            //     component: () =>
                            //       import('@/views/mall/control-center/shop/tabbar.vue'),
                            //   },
                            {
                                path: "/control-center/shop/category",
                                name: "shop_category",
                                meta: {
                                    title: "商品分类",
                                    parentPath: "/control-center",
                                    prevPath:
                                        "/control-center/shop/shop_decoration",
                                    icon: "icon_dianpu_fenlei",
                                    permission: ["view"],
                                    decorate: true,
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/decorate/category.vue"
                                    ),
                            },
                            {
                                path: "/control-center/shop/goods_detail",
                                name: "shop_goods_detail",
                                meta: {
                                    title: "商品详情",
                                    parentPath: "/control-center",
                                    prevPath:
                                        "/control-center/shop/shop_decoration",
                                    icon: "icon_dianpu_xiangqing",
                                    permission: ["view"],
                                    decorate: true,
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/decorate/goods_detail.vue"
                                    ),
                            },
                            {
                                path: "/control-center/shop/cart",
                                name: "shop_cart",
                                meta: {
                                    title: "购物车",
                                    parentPath: "/control-center",
                                    prevPath:
                                        "/control-center/shop/shop_decoration",
                                    icon: "icon_dianpu_shoppingCar",
                                    permission: ["view"],
                                    decorate: true,
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/decorate/cart.vue"
                                    ),
                            },
                            {
                                path: "/control-center/shop/user",
                                name: "shop_user",
                                meta: {
                                    title: "个人中心",
                                    parentPath: "/control-center",
                                    prevPath:
                                        "/control-center/shop/shop_decoration",
                                    icon: "icon_user",
                                    permission: ["view"],
                                    decorate: true,
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/decorate/user.vue"
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: "/control-center/goods/",
                name: "goods",
                meta: {
                    title: "商品",
                    parentPath: "/control-center",
                },
                redirect: "/control-center/goods/lists",
                component: Blank,
                children: [
                    {
                        path: "/control-center/goods/lists",
                        name: "goods_lists",
                        meta: {
                            title: "商品管理",
                            parentPath: "/control-center",
                            icon: "icon_goods",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/mall/control-center/goods/lists.vue"
                            ),
                    },
                    {
                        path: "/control-center/goods/category",
                        name: "goods_category",
                        meta: {
                            title: "分类管理",
                            parentPath: "/control-center",
                            icon: "icon_sort",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/mall/control-center/goods/category.vue"
                            ),
                    },
                    {
                        path: "/control-center/goods/brand",
                        name: "goods_brand",
                        meta: {
                            title: "品牌管理",
                            parentPath: "/control-center",
                            icon: "icon_pinpai",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/mall/control-center/goods/brand.vue"
                            ),
                    },
                    {
                        path: "/control-center/goods/unit",
                        name: "goods_unit",
                        meta: {
                            title: "商品单位",
                            icon: "icon_danwei",
                            parentPath: "/control-center",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/mall/control-center/goods/unit.vue"
                            ),
                    },
                    {
                        path: "/control-center/goods/supplier",
                        name: "goods_supplier",
                        meta: {
                            title: "供应商",
                            parentPath: "/control-center",
                            icon: "icon_gongyingshang",
                            permission: ["view"],
                        },
                        redirect: "/control-center/goods/supplier/lists",
                        component: secondary_meun,
                        children: [
                            {
                                path: "/control-center/goods/supplier/lists",
                                name: "supplier_lists",
                                meta: {
                                    title: "供应商管理",
                                    parentPath: "/control-center",
                                    prevPath: "/control-center/goods/supplier",
                                    permission: ["view"],
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/goods/supplier/lists.vue"
                                    ),
                            },
                            {
                                path: "/control-center/goods/supplier/category",
                                name: "supplier_category",
                                meta: {
                                    title: "供应商分类",
                                    parentPath: "/control-center",
                                    permission: ["view"],
                                    prevPath: "/control-center/goods/supplier",
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/goods/supplier/category.vue"
                                    ),
                            },
                            {
                                path: "/control-center/goods/supplier/edit",
                                name: "supplier_edit",
                                meta: {
                                    hidden: true,
                                    title: "新增供应商",
                                    parentPath: "/control-center",
                                    prevPath: "/goods/supplier/lists",
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/goods/supplier/edit.vue"
                                    ),
                            },
                            {
                                path: "/control-center/goods/release",
                                name: "goods_release",
                                meta: {
                                    hidden: true,
                                    title: "新增商品",
                                    parentPath: "/control-center",
                                    prevPath: "/goods/lists",
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/goods/release.vue"
                                    ),
                            },
                            {
                                path: "/control-center/goods/brand_edit",
                                name: "brand_edit",
                                meta: {
                                    hidden: true,
                                    title: "新增品牌",
                                    parentPath: "/control-center",
                                    prevPath: "/goods/brand",
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/goods/brand_edit.vue"
                                    ),
                            },
                            {
                                path: "/control-center/goods/category_edit",
                                name: "category_edit",
                                meta: {
                                    hidden: true,
                                    title: "新增分类",
                                    parentPath: "/control-center",
                                    prevPath: "/goods/category",
                                },
                                component: () =>
                                    import(
                                        "@/views/mall/control-center/goods/category_edit.vue"
                                    ),
                            },
                        ],
                    },
                    {
                        path: "/control-center/goods/evaluation",
                        name: "goods_evaluation",
                        meta: {
                            title: "评价管理",
                            parentPath: "/control-center",
                            icon: "icon_pingjia",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/mall/control-center/goods/evaluation.vue"
                            ),
                    },
                ],
            },
            {
                path: "/control-center/featuredCommodities/",
                name: "featuredCommodities",
                meta: {
                    title: "特色资源",
                    parentPath: "/control-center",
                },
                redirect: "/control-center/goods/featuredCommodities",
                component: Blank,
                children: [
                    {
                        path: "/control-center/goods/featuredCommodities",
                        name: "featuredCommodities",
                        meta: {
                            title: "特色资源列表",
                            parentPath: "/control-center",
                            icon: "icon_goods",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/mall/control-center/goods/featuredCommodities.vue"
                            ),
                    },
                ],
            },
        ],
    },
];

export default routes;
