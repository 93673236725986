
import Blank from "@/layout/blank.vue";

import MainNoHeader from "@/layout/main-noHeader.vue";

import secondary_meun from "@/layout/secondary_meun-control-center.vue";


const routes = [
  {
    path: "/platform-permission",
    name: "platform-permission",
    meta: { title: "设置", hidden: true },
    redirect: "/platform-permission/platform/config",
    component: MainNoHeader,
    children: [
      {
        path: "/platform-permission/permissions",
        name: "platform-permission-admin",
        meta: {
          title: "平台权限",
          parentPath: "/platform-permission",
          icon: "el-icon-unlock",
        },
        component: Blank,
        redirect: "/platform-permission/permissions/admin",
        children: [
          {
            path: "/platform-permission/permissions/admin",
            name: "platform-permission-permissions_admin",
            meta: {
              title: "管理员",
              parentPath: "/platform-permission",
              permission: ["view","delete","add","detail","edit"],
            },
            component: () => import("@/views/setting/permissions/admin.vue"),
          },
          {
            path: "/platform-permission/permissions/admin_edit",
            name: "platform-permission-permissions_admin_edit",
            meta: {
              hidden: true,
              title: "管理员",
              parentPath: "/platform-permission",
              prevPath: "/platform-permission/permissions/admin",
              permission: ["edit"],
            },
            component: () =>
              import("@/views/setting/permissions/admin_edit.vue"),
          },
          {
            path: "/platform-permission/permissions/role",
            name: "platform-permission-permissions_role",
            meta: {
              title: "角色",
              parentPath: "/platform-permission",
              permission: ["view","delete","add","detail","edit"],
            },
            component: () => import("@/views/setting/permissions/role.vue"),
          },
          {
            path: "/platform-permission/permissions/role_edit",
            name: "platform-permission-permissions_role_edit",
            meta: {
              hidden: true,
              title: "编辑角色",
              parentPath: "/platform-permission",
              prevPath: "/platform-permission/permissions/role",
              permission: ["edit"],
            },
            component: () =>
              import("@/views/setting/permissions/role_edit.vue"),
          },
        ],
      },
    ],
  },
];

export default routes;
