import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";

import MainNoHeader from "@/layout/main-noHeader.vue";

import secondary_meun from "@/layout/secondary_meun-control-center.vue";

const routes = [
    {
        path: "/setting",
        name: "setting",
        meta: { title: "设置中心" },
        redirect: "/setting/platform/config",
        component: Main,
        children: [
            {
                path: "/setting/platform/config",
                name: "platform",
                meta: {
                    title: "平台设置",
                    parentPath: "/setting",
                    icon: "icon-pingtaishezhi",
                },
                component: Blank,
                redirect: "/setting/platform/config",
                children: [
                    {
                        path: "/setting/platform/config",
                        name: "config",
                        meta: {
                            title: "平台信息",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/platform/config.vue"),
                    },
                    {
                        path: "/setting/platform/keep_on_record",
                        name: "keep_on_record",
                        meta: {
                            title: "备案信息",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/setting/platform/keep_on_record.vue"
                            ),
                    },
                    // {
                    //     path: "/setting/platform/csm_api_config",
                    //     name: "csm_api_config",
                    //     meta: {
                    //         title: "CSM对接",
                    //         parentPath: "/setting",
                    //         permission: ["view"],
                    //     },
                    //     component: () =>
                    //         import(
                    //             "@/views/setting/platform/csm_api_config.vue"
                    //         ),
                    // },
                ],
            },
            {
                path: "/setting/service",
                name: "service",
                meta: {
                    title: "客服设置",
                    parentPath: "/setting",
                    icon: "icon-kefushezhi",
                },
                component: Blank,
                redirect: "/setting/service/service",
                children: [
                    {
                        path: "/setting/service/service",
                        name: "config",
                        meta: {
                            title: "联系客服",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/service/service.vue"),
                    },
                    {
                        path: "/setting/service/auth",
                        name: "config",
                        meta: {
                            title: "客服授权",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/service/auth.vue"),
                    },
                    {
                        path: "/setting/service/speech",
                        name: "config",
                        meta: {
                            title: "客服话术",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/service/speech.vue"),
                    },
                ],
            },
            {
                path: "/setting/security/account",
                name: "security",
                meta: {
                    title: "安全设置",
                    parentPath: "/setting",
                    icon: "icon-kefushezhi",
                },
                component: Blank,
                redirect: "/setting/security/account",
                children: [
                    {
                        path: "/setting/security/account",
                        name: "journal",
                        meta: {
                            title: "修改密码",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/security/account.vue"),
                    },
                    {
                        path: "/setting/security/verify",
                        name: "journal",
                        meta: {
                            title: "后台验证设置",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/security/verify.vue"),
                    },
                ],
            },
            {
                path: "/setting/payment/pay_method_edit",
                name: "setting_payment",
                meta: {
                    title: "支付设置",
                    parentPath: "/setting",
                    icon: "icon_set_pay",
                },
                component: Blank,
                redirect: "/setting/payment/pay_method_edit",
                children: [
                    {
                        path: "/setting/payment/pay_method",
                        name: "setting_pay_method",
                        meta: {
                            title: "支付方式",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/payment/pay_method.vue"),
                    },
                    {
                        path: "/setting/payment/pay_method_edit",
                        name: "setting_pay_method_edit",
                        meta: {
                            hidden: true,
                            title: "支付方式配置",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/setting/payment/pay_method_edit.vue"
                            ),
                    },
                    {
                        path: "/setting/payment/pay_config",
                        name: "setting_pay_config",
                        meta: {
                            title: "支付配置",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/payment/pay_config.vue"),
                    },
                    {
                        path: "/setting/payment/pay_config_edit",
                        name: "setting_pay_config_edit",
                        meta: {
                            hidden: true,
                            title: "支付配置设置",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/setting/payment/pay_config_edit.vue"
                            ),
                    },
                ],
            },
            {
                path: "/setting/permissions",
                name: "admin",
                meta: {
                    title: "平台权限",
                    parentPath: "/setting",
                    icon: "icon_set_quanxian",
                },
                component: Blank,
                redirect: "/setting/permissions/admin",
                children: [
                    {
                        path: "/setting/permissions/admin",
                        name: "permissions_admin",
                        meta: {
                            title: "管理员",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/permissions/admin.vue"),
                    },
                    {
                        path: "/setting/permissions/admin_edit",
                        name: "permissions_admin_edit",
                        meta: {
                            hidden: true,
                            title: "管理员",
                            parentPath: "/setting",
                            prevPath: "/setting/permissions/admin",
                        },
                        component: () =>
                            import(
                                "@/views/setting/permissions/admin_edit.vue"
                            ),
                    },
                    {
                        path: "/setting/permissions/role",
                        name: "permissions_role",
                        meta: {
                            title: "角色",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/permissions/role.vue"),
                    },
                    {
                        path: "/setting/permissions/role_edit",
                        name: "permissions_role_edit",
                        meta: {
                            hidden: true,
                            title: "编辑角色",
                            parentPath: "/setting",
                            prevPath: "/setting/permissions/role",
                        },
                        component: () =>
                            import("@/views/setting/permissions/role_edit.vue"),
                    },
                ],
            },
            {
                path: "/setting/system_maintain/journal",
                name: "system_maintain",
                meta: {
                    title: "系统维护",
                    parentPath: "/setting",
                    icon: "icon-xitongweihu",
                },
                component: Blank,
                redirect: "/setting/system_maintain/journal",
                children: [
                    {
                        path: "/setting/system_maintain/journal",
                        name: "journal",
                        meta: {
                            title: "系统日志",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/setting/system_maintain/journal.vue"
                            ),
                    },
                    {
                        path: "/setting/system_maintain/cache",
                        name: "cache",
                        meta: {
                            title: "系统缓存",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/system_maintain/cache.vue"),
                    },
                    //   {
                    //     path: "/setting/system_maintain/updata",
                    //     name: "updata",
                    //     meta: {
                    //       title: "系统升级",
                    //       // hidden: true,
                    //       parentPath: "/setting",
                    //       permission: ["view"],
                    //     },
                    //     component: () =>
                    //       import("@/views/setting/system_maintain/updata.vue"),
                    //   },
                    // {
                    //     path: "/setting/system_maintain/environment",
                    //     name: "environment",
                    //     meta: {
                    //         title: "系统环境",
                    //         // hidden: true,
                    //         parentPath: "/setting",
                    //         permission: ["view"],
                    //     },
                    //     component: () =>
                    //         import(
                    //             "@/views/setting/system_maintain/environment.vue"
                    //         ),
                    // },
                    {
                        path: "/setting/system_maintain/task",
                        name: "task",
                        meta: {
                            title: "定时任务",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/setting/system_maintain/task/lists.vue"
                            ),
                    },
                    {
                        path: "/setting/system_maintain/task_edit",
                        name: "task_edit",
                        meta: {
                            hidden: true,
                            title: "定时任务",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import(
                                "@/views/setting/system_maintain/task/edit.vue"
                            ),
                    },
                ],
            },
            {
                path: "/setting/storage",
                name: "order",
                meta: {
                    title: "存储设置",
                    parentPath: "/setting",
                    icon: "icon-taocanguanli",
                },
                component: Blank,
                redirect: "/setting/storage/storage",
                children: [
                    {
                        path: "/setting/storage/storage",
                        name: "setting_storage",
                        meta: {
                            title: "设置A",
                            parentPath: "/setting",
                            hidden: true,
                        },
                        component: () =>
                            import("@/views/setting/storage/storage.vue"),
                    },
                    {
                        path: "/setting/storage/index",
                        name: "setting_storage_index",
                        meta: {
                            title: "存储设置",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/storage/index.vue"),
                    },
                ],
            },
            {
                path: "/delivery/logistics",
                name: "order",
                meta: {
                    title: "物流设置",
                    parentPath: "/delivery",
                    icon: "icon-taocanguanli",
                    permission: ["view"],
                },
                component: Blank,
                redirect: "/setting/delivery/logistics",
                children: [
                    {
                        path: "/setting/delivery/logistics",
                        name: "delivery_logistics",
                        meta: {
                            title: "物流接口",
                            parentPath: "/setting",
                            prevPath: "/setting/delivery/index",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/delivery/logistics.vue"),
                    },
                    {
                        path: "/setting/delivery/express",
                        name: "delivery_logistics",
                        meta: {
                            title: "快递公司",
                            parentPath: "/setting",
                            prevPath: "/setting/delivery/index",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/delivery/express.vue"),
                    },
                    {
                        path: "/setting/delivery/express_edit",
                        name: "delivery_express_edit",
                        meta: {
                            hidden: true,
                            title: "新增快递公司",
                            parentPath: "/setting",
                            prevPath: "/setting/delivery/index",
                            permission: ["add"],
                        },
                        component: () =>
                            import("@/views/setting/delivery/express_edit.vue"),
                    },
                ],
            },
            // 消息通知
            {
                path: "/sms",
                name: "sms",
                meta: {
                    title: "消息设置",
                    moduleName: "sms",
                    parentPath: "/setting",
                    icon: "icon_set_jiaoyi",
                },
                redirect: "/sms/buyers/buyers",
                component: Blank,
                children: [
                    {
                        path: "/email/smtp",
                        name: "sms_buyers",
                        meta: {
                            title: "邮件SMTP配置",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/smtp/setting.vue"),
                    },
                    {
                        path: "/sms/buyers/buyers",
                        name: "sms_buyers",
                        meta: {
                            title: "通知买家",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/sms/buyers/buyers.vue"),
                    },
                    {
                        path: "/sms/buyers/setting",
                        name: "setting",
                        meta: {
                            title: "设置",
                            hidden: true,
                            parentPath: "/setting",
                        },
                        component: () =>
                            import("@/views/setting/sms/buyers/setting.vue"),
                    },
                    {
                        path: "/sms/buyers/business",
                        name: "business",
                        meta: {
                            title: "验证码",
                            parentPath: "/setting",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/sms/buyers/business.vue"),
                    },
                    {
                        path: "/sms/seller",
                        name: "seller",
                        meta: {
                            title: "卖家通知",
                            parentPath: "/setting",
                            icon: "icon_notice_seller",
                            permission: ["view"],
                        },
                        component: () =>
                            import("@/views/setting/sms/seller.vue"),
                    },
                    {
                        path: "/sms/sms",
                        name: "sms",
                        meta: {
                            title: "短信设置",
                            parentPath: "/setting",
                            icon: "icon_notice_mail",
                            permission: ["view"],
                        },
                        component: () => import("@/views/setting/sms/sms.vue"),
                    },
                    {
                        path: "/sms/sms_edit",
                        name: "sms_edit",
                        meta: {
                            hidden: true,
                            title: "短信设置详情",
                            parentPath: "/setting",
                            prevPath: "/sms/sms",
                        },
                        component: () =>
                            import("@/views/setting/sms/sms_edit.vue"),
                    },
                ],
            }, {
                path: "/channel",
                name: "channel",
                meta: {
                    title: "渠道设置",
                    moduleName: "channel",
                    parentPath: "/setting",
                    icon: "icon_set_jiaoyi",
                },
                redirect: "/sms/buyers/buyers",
                component: Blank,
                children: [
                    {
                        path: '/mp_wechat/index',
                        name: 'mp_wechat_index',
                        meta: {
                            title: '微信公众号',
                            parentPath: '/setting',
                            permission: ['view']
                        },
                        component: () => import('@/views/channel/mp_wechat/index.vue')
                    },
                ],
            },
            // 消息通知
        ],
    },
];

export default routes;
