import app from './app'
import user from './user'
import decorate from './decorate'
const editor = require('./editor')
export default {
    app,
    user,
    decorate,
    editor: editor.default
}
