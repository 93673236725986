


















import {
    Component,
    Vue,
    Prop,
    PropSync,
    Model,
    Watch,
    Provide,
    Inject,
    Emit,
    Ref,
} from "vue-property-decorator";
@Component({})
export default class Tabs extends Vue {
    editableTabsValue: string = "";
    editableTabs: any = [];

    removeTab(targetName: any) {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
            tabs.forEach((tab: any, index: number) => {
                if (tab.url === targetName) {
                    let nextTab = tabs[index + 1] || tabs[index - 1];
                    if (nextTab) {
                        activeName = nextTab.url;
                    }
                }
            });
        }

        this.editableTabsValue = activeName;
        if (this.$route.path !== this.editableTabsValue)
            this.$router.push(this.editableTabsValue);
        this.editableTabs = tabs.filter((tab: any) => tab.url !== targetName);
    }
    handleClick(tab: any) {
        if (this.$route.path !== tab.name) {
            this.$router.push(tab.name);
        }
    }

    @Watch("$route", { immediate: true, deep: true })
    onRouteChanged(val: any) {
        let url = "";
        let query: any = [];
        for (const key in val.query) {
            if (Object.prototype.hasOwnProperty.call(val.query, key)) {
                const pat: any = val.query[key];
                query.push(key + "=" + pat);
            }
        }
        if (query.length == 0) {
            url = val.path;
        } else {
            url = val.path + "?" + query.join("&");
        }
        if (
            this.editableTabs.findIndex(
                (item: any) => item.url === url
            ) === -1 &&
            !val.meta.hidden
        ) {
            this.editableTabs.push({
                title: val.meta.title,
                url: url,
                key: val.path,
            });
        }

        this.editableTabsValue = url;
    }
}
