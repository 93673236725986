import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Secondary from "@/layout/secondary.vue";
import index from "./modules";
import trade from "./modules/trade";
import after_sales from "./modules/after_sales";
import shop from "./modules/shop";
import card from "./modules/card";
import mall from "./modules/mall";
import supplier from "./modules/supplier";
import goods from "./modules/goods";
import finance from "./modules/finance";
import financial from "./modules/financial";
import setting from "./modules/setting";
import purchase from "./modules/purchase";
import controlCenter from "./modules/control-center";
import platformPermission from "./modules/platform-permission";
import data_center from "./modules/data_center";



Vue.use(VueRouter);

export const asyncRoutes: Array<RouteConfig> = [
  ...index, //首页
  ...shop, //店铺
  ...card, //卡券
  ...mall, //商城
  ...supplier, //供应商
  ...goods, //商品
  ...trade, // 订单
  ...after_sales,
  ...finance, //结算
  ...data_center,
  //   ...financial, //财务
  ...setting, //设置
  /*...purchase,*/
  ...controlCenter,
  ...platformPermission
];

const constantRoutes: Array<RouteConfig> = [
  {
    path: "*",
    redirect: "/error/404",
  },
  {
    path: "/account",
    name: "account",
    component: Secondary,
    redirect: "/account/login",
    children: [
      {
        path: "/account/login",
        name: "login",
        meta: {
          title: "登录",
          parentPath: "/account",
        },
        component: () => import("@/views/account/login.vue"),
      },
    ],
  },
  {
    path: "/webview",
    name: "webview",
    component: Secondary,
    redirect: "/webview/login",
    children: [
      {
        path: "/webview/login",
        name: "webview",
        meta: {
          title: "登录",
          parentPath: "/webview",
        },
        component: () => import("@/views/webView.vue"),
      },
    ],
  },
  {
    path: "/error",
    name: "error",
    component: Secondary,
    redirect: "/error/404",
    children: [
      {
        path: "/error/404",
        name: "error_404",
        meta: {
          title: "404",
          parentPath: "/error",
        },
        component: () => import("@/views/error/404.vue"),
      },
      {
        path: "/error/500",
        name: "error_500",
        meta: {
          title: "500",
          parentPath: "/error",
        },
        component: () => import("@/views/error/500.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...asyncRoutes, ...constantRoutes],
});

export default router;
