

























































import { apiSystemThemePage } from "@/api/shop";
import { RequestPaging } from "@/utils/util";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import LsPagination from "@/components/ls-pagination.vue";
@Component({
  components: {
    LsPagination,
  },
})
export default class Details extends Vue {
  @Prop({ default: "popup" }) type!: string;
  @Prop({ default: 2 }) pageSize!: number;
  lists = [];
  pagesInfo = {
    type: 1,
    name: "默认页面",
    common: {
      title: "微页面",
      background_type: "0",
      bg_color: "#F5F5F5",
      background_image: "",
    },
    content: [],
  };
  pager = new RequestPaging({ size: this.pageSize });

  getList(page?: number) {
    page && (this.pager.page = page);
    this.pager.request({
      callback: apiSystemThemePage,
      params: {
        ...this.$route.query,
      },
    });
  }
  handleCreate(data: any) {
    data = data == "empty" ? this.pagesInfo : data;
    this.$emit("select", data);
  }
  created() {
    this.getList(1);
  }
}
