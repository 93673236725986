

















import { Component, Vue } from "vue-property-decorator";
import { asyncRoutes } from "@/router";
import LsAsideControlCenter from "@/components/layout/secondary_aside-control-center.vue";
import Perm from "@/components/layout/perm.vue";
import DecorateLayout from "@/layout/decorate-control-center.vue";

@Component({
  components: {
    LsAsideControlCenter,
    Perm,
    DecorateLayout,
  },
})
export default class secondary_meun extends Vue {
  get asideMenu() {
    const { meta } = this.$route;
    let item = meta?.moduleName
      ? asyncRoutes.find((item) => item.meta?.moduleName === meta?.moduleName)
      : asyncRoutes.find((item) => item.path === meta?.parentPath);
    let menu = item?.children;
    let route: any[] = [];
    (menu as Array<any>).map((v) => {
      if (v.path == meta?.prevPath) {
        route = v.children;
      } else {
        if (v?.children) {
          v?.children.map((k: any) => {
            if (k.path == meta?.prevPath) {
              route = k.children;
            }
          });
        }
      }
    });
    return route;
    // return item?.children;
  }
  get hideAsideMenu() {
    this.asideMenu?.every((item: any) => {});
    return this.asideMenu?.every((item: any) => item.meta?.hidden);
  }

  get decorateShow() {
    const { meta } = this.$route;
    return meta?.decorate || false;
  }
}
