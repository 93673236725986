import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
const routes = [
    {
        path: "/mall",
        name: "mall",
        meta: { title: "商城管理" },
        redirect: "/mall/mall_list",
        component: Main,
        children: [
            {
                path: "/mall/mall_list",
                name: "mall_list",
                meta: {
                    title: "商城列表",
                    parentPath: "/mall",
                    icon: 'icon_dianpu_home',
                    permission: ["view"],
                },
                component: () => import("@/views/mall/mall_list/index.vue"),
            },
            {
                path: "/mall/lists_detail",
                name: "lists_detail",
                meta: {
                    hidden: true,
                    title: "商城信息",
                    parentPath: "/mall",
                    prevPath: "/mall/mall_list",
                    icon: "icon_dianpu_home",
                    permission: ["view",'add'],
                },
                component: () =>
                    import("@/views/mall/mall_list/lists_edit.vue"),
            },
            {
                path: "/mall/modify_account",
                name: "modify_account",
                meta: {
                    hidden: true,
                    title: "修改超级管理员账号",
                    parentPath: "/mall",
                    prevPath: "/mall/mall_list",
                    icon: "icon_dianpu_home",
                    permission: ["view"],
                },
                component: () =>
                    import("@/views/mall/mall_list/modify_account.vue"),
            },
            /*{
                path: "/mall/set_meal",
                name: "set_meal",
                meta: {
                    title: "套餐列表",
                    parentPath: "/mall",
                    permission: ["view"],
                },
                component: () => import("@/views/mall/set_meal/lists.vue"),
            },*/
            {
                path: "/mall/set_meal_edit",
                name: "set_meal_edit",
                meta: {
                    hidden: true,
                    title: "商城套餐",
                    parentPath: "/mall",
                    prevPath: "/mall/set_meal",
                    permission: ["view"],
                },
                component: () => import("@/views/mall/set_meal/edit.vue"),
            },
        ],
    },
];

export default routes;
