











































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Aside extends Vue {
  @Prop({
    default: () => {
      return [];
    },
  })
  menu!: any[];
  get activePath() {
    return this.$route.path;
  }
}
