import Main from "@/layout/main.vue";
import Blank from "@/layout/blank.vue";
const routes = [
    {
        path: "/after_sales",
        name: "after_sales",
        meta: { title: "售后中心" },
        redirect: "/after_sales/after_sales_list",
        component: Main,
        children: [
            {
                path: "/after_sales/after_sales_list",
                name: "after_sales_list",
                meta: {
                    title: "售后处理",
                    parentPath: "/after_sales",
                    icon: "icon_order_guanli",
                    permission: ["view","detail"],
                },
                component: () =>
                    import("@/views/after_sales/after_sales_list/index.vue"),
            },
            {
                path: "/after_sales/after_sales_detail",
                name: "after_sales_detail",
                meta: {
                    title: "售后详情",
                    hidden: true,
                    parentPath: "/after_sales",
                    icon: "el-icon-s-data",
                    permission: ["view","detail"],
                },
                component: () =>
                    import("@/views/after_sales/after_sales_list/detail.vue"),
            },
            {
                path: "/compensation/compensation_list",
                name: "compensation_list",
                meta: {
                    title: "赔付单",
                    parentPath: "/after_sales",
                    icon: 'icon_order_shouhou',
                    permission: ["view","detail"],
                },
                component: () =>
                    import("@/views/after_sales/compensation/index.vue"),
            },
            {
                path: "/after_sales/synInquire",
                name: "synInquire",
                meta: {
                    title: "综合查询",
                    parentPath: "/after_sales",
                    icon: 'tradingdata',
                    permission: ["view","detail"],
                },
                component: () =>
                    import("@/views/after_sales/synInquire/index.vue"),
            },
            
        ],
    },
];

export default routes;
