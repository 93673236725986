// 开发环境域名
 const host_development = 'http://www.eqiai.yj2insaas.cc'
// const host_development = 'http://www.t4.com'
// const host_development = 'http://www.new.tcjy.t4.eqiai.net/'
// const host_development = 'http://www.t4_dev.dealbao.cn/'
export default {
  // 版本
  version: "1.6.3",
  storeid: '66',
  baseURL: process.env.NODE_ENV == "production" ? "" : host_development,
  tencentMapKey: "FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW",
};
